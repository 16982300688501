import * as React from "react"
import SocialIcon from "./SocialIcon"
import Icon from "../images/icon.png"
import FACEBOOK_ICON from "../images/facebook.png"
import INSTAGRAM_ICON from "../images/instagram.png"
import LINKEDIN_ICON from "../images/linkedin.png"
import TWITTER_ICON from "../images/twitter.png"
import EMAIL_ICON from "../images/email.png"

export default class Footer extends React.Component {

    TagLine = () => {
        return <div style={styles.taglineContainer}>
            <div>{"YOUR LIMB"}</div>
            <div style={styles.taglineSpacer}>{"|"}</div>
            <div>{"YOUR STYLE"}</div>
        </div>
    }

    render() {
        return <footer style={styles.footer}>
            {this.TagLine()}{""}
            <div style={styles.stripeContainer}>
                <div style={{backgroundColor: '#37b336', width: '120%', height: 4, marginBottom: 4}}/>
                <div style={{backgroundColor: '#ff0082', width: '120%', height: 4, marginTop: 4}}/>
            </div>
            <div style={styles.socialIconWrapper}>
                <SocialIcon src={FACEBOOK_ICON} link="https://www.facebook.com/StyleLimb"/>
                <SocialIcon src={INSTAGRAM_ICON} link="https://www.instagram.com/stylelimb/"/>
                <SocialIcon src={TWITTER_ICON} link="https://twitter.com/_StyleLimb"/>
                <SocialIcon src={EMAIL_ICON} link="mailto:joshuac9894@gmail.com"/>
                {/* <SocialIcon src={LINKEDIN_ICON} link=""/> */}
            </div>
            <div style={styles.copywrite}>
                {`StyleLimb | Copyright`} 
                <div style={styles.copywriteSymbol}>&copy;</div>
                {new Date().getFullYear()}
            </div>
        </footer>
    }
}

const styles = {
    footer: {
        marginBottom: 16,
        width: '100%',
    },
    stripeContainer: {
        alignItems: 'center',
        display: 'flex', 
        flexDirection: 'column',
        marginBottom: 42,
        marginTop: 16,
    },
    copywrite: {
        fontFamily: "Lato",
        color: "rgba(0, 0, 0, 0.65)",
        fontSize: 16,
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 32
    },
    copywriteSymbol: {
        marginLeft: 4,
        marginRight: 4
    },
    socialIconWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center"
    },
    taglineContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        fontFamily: "Lato",
        fontSize: 28,
        fontWeight: 'regular',
        color: 'rgba(0,0,0,0.65)',
    },
    taglineSpacer: {
        marginLeft: 32,
        marginRight: 32
    }
}