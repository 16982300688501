import * as React from "react"
// import { FB } from 'gatsby-plugin-facebook-customer-chat'

const FBChatButton = () => {
    React.useEffect(() => {
        window.fbAsyncInit = function() {
            window.FB.init({
            xfbml            : true,
            version          : 'v13.0'
            })
        }

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    })

    return <div>
        <div id="fb-root"></div>
        <div id="fb-customer-chat" 
             className="fb-customerchat"
             attribution="biz_inbox"
             page_id="108055405169109"
        />
    </div>
}

export default FBChatButton    