import * as React from "react"

export default class Header extends React.Component {
    render() {
        return <div style={styles.container}>
            <div style={styles.subheading}>{"Introducing"}</div>
            <div style={styles.headingConteiner}>
                <div style={{...styles.heading, color: '#ff0082'}}>{"Style"}</div>
                <div style={{...styles.heading, color: '#37b336'}}>{"Limb"}</div>
            </div>
        </div>
    }
}

const styles = {
    container: {
        marginTop: 32,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontFamily: "Lato",
    },
    headingConteiner: {
        display: 'flex',
        alignItems: 'center',
    },
    heading: {
        fontSize: 54,
        fontStyle: 'bold'
    },
    subheading: {
        fontSize: 16,
        color: 'rgba(0,0,0,0.65)'
    }
}