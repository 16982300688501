import * as React from "react"

const Button = ({label, link}) => {
    const [hover, setHover] = React.useState(false);

    const toggleHover = () => setHover(!hover);

    return <a 
        style={{textDecoration: 'none'}}
        href={link} 
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
    >
        <div style={{
            ...styles.touchArea, 
            backgroundColor: hover ? '#8c1553' : '#ff0082'
        }}>
            {label}
        </div>
    </a>
}

const styles = {
    touchArea: {
        color: '#fff',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 8,
        fontWeight: 'bold',
    }
}

export default Button