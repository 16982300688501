import * as React from "react"
import "../styles/style.css"

import Footer from "../components/Footer"
import Header from "../components/Header"
import Button from "../components/Button"
import FBChatButton from "../components/FBChatButton"

import CARBON_FIBRE from "../images/products/carbon-fibre.png"
import HEXAGONAL from "../images/products/hexagonal.png"
import TWO_PIECE from "../images/products/two-piece.png"

export default class IndexPage extends React.Component {
  render() {
    return <main style={styles.container}>
      <title>StyleLimb</title>

      <div style={styles.contentWrapper}>
        <Header />

        <div style={styles.contentContainer}>
          <div style={styles.contentPanel}>
            <img style={styles.product} src={CARBON_FIBRE}/>
            {/* <img style={styles.product} src={HEXAGONAL}/> */}
            <img style={styles.product} src={TWO_PIECE}/>
          </div>

          <div style={styles.contentPanel}>
            <div style={{fontSize: 22}}>{"Prosthetic leg covers"}</div>
            <ul>
              <li>{"Customisable"}</li>
              <li>{"Lightweight"}</li>
              <li>{"Ergonomic design"}</li>
              <li>{"Low cost"}</li>
            </ul>
            <div style={{marginBottom: 8, marginRight: 16}}>{"At StyleLimb, our goal is to create stylish and customisable prosthetic leg covers at an affordable price."}</div>
            <div style={{marginRight: 16}}>{"Using human-factor design principles and modern 3D printing technology, we have developed a range of products to fit most transtibial & transfemeral devices."}</div>

            <Button label={"Try our product"} link={"https://facebook.com"}/>

            
          </div>
        </div>
        <Footer />
      </div>


      <FBChatButton />
    </main>
  }
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection:'column',    
    alignItems: 'center'
  },
  contentWrapper: {
    width: '65%',
    height: '100%',
    backgroundColor: '#F6F9FA',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
  },
  contentContainer: {
    display: 'flex',

  },
  contentPanel: {
    width: '100%',
    height: '100%',
    fontFamily: "Lato",
    color: "rgba(0, 0, 0, 0.65)",
    fontSize: 16,
  },
  product: {
    width: 200,
  }
}