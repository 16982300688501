import * as React from "react"

const SocialIcon = ({src, link}) => {
    const [hover, setHover] = React.useState(false);

    const toggleHover = () => setHover(!hover);

    return <a 
        href={link} 
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
    >
        <div style={styles.touchArea}>
            <img style={hover ? styles.iconHover : styles.icon} src={src}/>
        </div>
    </a>
}

const styles = {
    touchArea: {
        width: 46,
        height: 46,
        marginLeft: 16,
        marginRight: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {
        width: 42,
        height: 42,
        opacity: 0.7,
        marginBottom: 0,
    },
    iconHover: {
        width: 40,
        height: 40,
        opacity: 1.0,
        marginBottom: 16
    },
}

export default SocialIcon;